.chip {
  display: inline-flex;
  padding: 0 15px;
  align-items: center;
  height: 30px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 5px;
  margin: 6px;
  background-color: #fff;
  cursor: pointer;
}

.chip img {
  float: left;
  margin: 0 10px 0 -25px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}