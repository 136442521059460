.nav-menu {
  background-color: rgba(255, 255, 255, 1);
  width: 220px;
  /* height: calc(100vh-36px); */
  display: flex;
  justify-content: center;
  z-index: 1;
  position: fixed;
  top: 60px;
  left: -100%;
  transition: 0.5s;
}

.nav-menu.active {
  left: 0;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  width: 100%;
  height: 59px;
}

.nav-text a {
  text-decoration: none;
  color: #333333;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.nav-text a:hover {
  background-color: rgba(245, 245, 246, 1);
  color: inherit;
}

.nav-menu-items {
  width: 100%;
  padding-left: 0;
}

.navbar-toggle {
  width: 100%;
  height: 46px;
  display: flex;
}

.nav--collapsed {
  width: 50px;
  transition: 0.3s;
}

.navbar-scroll {
  overflow: auto;
  bottom: 0;
}

.nav--collapsed .nav-label-hide {
  display: none;
  transition: 0.3s;
}

.icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.nav-label {
  font-family: 'Montserrat-Medium', 'Montserrat Medium', 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-align: left;
}

.icon-container i {
  font-weight: 400;
  font-style: normal;
  color: #3CAF8A;
  text-align: center;
}

.nav-static-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.nav-static-item:hover {
  background-color: rgba(245, 245, 246, 1);
  color: inherit;
}