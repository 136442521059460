$primary: #3caf8a;
$secondary: #ff8360;

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.btn-primary {
    color: #fff;
}

.btn-primary:hover {
    color: #fff;
}

.btn-outline-secondary:hover {
    color: #fff;
}

.btn-primary:focus {
    color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(0, 0, 0, 0);
    outline: 0 none;
}
