.backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.9s;
    z-index: 100;
}

.side-panel {
    position: fixed;
    width: 350px;
    height: 100%;
    top: 0;
    right: -100%;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 0px;
    box-shadow: none;
    transition: 0.4s;
    z-index: 200;
}

.side-panel.active {
    right: 0
}