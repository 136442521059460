 ol.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    margin-bottom: 0.5rem;
  }

  ol.breadcrumb li {
    display: inline;
    font-family: 'Montserrat-Medium', 'Montserrat Medium', 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    color: #3CAF8A;
  }
  
  ol.breadcrumb li.breadcrumb-item + li.breadcrumb-item:before {
    color: black;
    content: ">";
  }
  
  ol.breadcrumb li a {
    color: #3CAF8A;
    text-decoration: none;
  }
  
  ol.breadcrumb li a:hover {
    color: #3CAF8A;
    text-decoration: underline;
  } 

  ol.breadcrumb li span {
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }