.container-margin-style {
    margin-left: 220px;
    transition: 0.3s;
    margin-top: 60px;
}

.container-no-margin-style {
    margin-left: 50px;
    transition: 0.3s;
    margin-top: 60px;
}