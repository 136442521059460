div .addPermissionWrapper {
    width: 137px;
    height: 43px;
    background: inherit;
    background-color: rgba(221, 221, 221, 0);
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}

thead .tHeadStyling {
    height: 40px;
    background-color: #F5F5F6;
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: none;
    /* text-align: center; */
}

div .actionBtn {
    float: right;
    padding-top: 1px;
    height: 20px;
    font-size: 10px;
}

.treenode:hover {
    background-color: lightgrey;
}

div .addContactWrapper {
    width: 137px;
    height: 43px;
    background: inherit;
    background-color: rgba(221, 221, 221, 0);
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}