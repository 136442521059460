.cantAccessYourAccount {
  font-family: 'Montserrat-SemiBold', 'Montserrat SemiBold', 'Montserrat', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #3CAF8A;
  text-align: center;
  cursor: pointer;
}

.loginImgStyling {
  width: 200px;
  height: 41px;
}

div .loginTitleStyling {
  font-family: 'Montserrat Medium', 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #7E786F;
  text-align: center;
}

div .loginCardStyling {
  max-width: 480px;
  max-height: 520px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  box-shadow: none;
  z-index: 2;
  opacity: 1;
}

h3 .signInLabelStyling {
  font-family: 'Montserrat Bold', 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  letter-spacing: 0.1px;
}

footer .contInfinityLabelStyling {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.1px;
}

div .loginInputStyling {
  margin-top: 20px;
  height: 40px;
  padding: 2px 2px 2px 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: normal;
  color: #333333;
  vertical-align: none;
  text-align: left;
  text-transform: none;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #666;
}

div .loginInputStyling:focus {
  box-shadow: none;
  border-bottom-color: #666;
  background-color: #f2f2f2;
}

div .signInButtonStyling {
  border-width: 0px;
  width: 166px;
  height: 50px;
  background-color: rgba(60, 175, 138, 1);
  border: none;
  border-radius: 50px;
  box-shadow: none;
  font-family: 'Montserrat-SemiBold', 'Montserrat SemiBold', 'Montserrat', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 10px;
}

div .signInButtonStyling:hover {
  background-color: rgba(60, 175, 138, 0.8);
  color: #FFFFFF;
}

div .SSOButtonStyling {
  border-width: 0px;
  width: 166px;
  height: 50px;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(60, 175, 138, 1);
  border-radius: 50px;
  box-shadow: none;
  font-family: 'Montserrat-SemiBold', 'Montserrat SemiBold', 'Montserrat', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 14px;
  color: #3CAF8A;
  margin-top: 10px;
}

div .SSOButtonStyling:hover {
  color: #3CAF8A;
}

.loginLogoImgStyling {
  border-width: 0px;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 240px;
  height: 34px;
}

