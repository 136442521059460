.content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
}

.content h2 {
    padding: 0px 0 5px 0px;
}

/* .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
} */

.content p {
    width: 100%;
    height: 100%;
}

.active-content {
    display: block;
}

.bold-style {
    font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
}

.medium-style {
    font-family: 'Montserrat-Medium', 'Montserrat Medium', 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
}

.bold-color-style {
    font-family: 'Montserrat-Bold', 'Montserrat Bold', 'Montserrat', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    color: #3CAF8A;
    text-align: center;
}

.semi-bold-style {
    font-family: 'Montserrat-SemiBold', 'Montserrat SemiBold', 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
}

.regular-style {
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

div .modal-btn {
    background-color: rgba(60, 175, 138, 1);
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-family: 'Montserrat-Medium', 'Montserrat Medium', 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
}

div .modal-btn:hover {
    background-color: rgba(60, 175, 138, 1);
    color: #FFFFFF;
}

div .modal-btn:focus,
.modal-btn:active,
.modal-btn.active,
.modal-btn:focus:active {
    background-image: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

div .dasboard-card-style {
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(215, 215, 215, 1);
    border-radius: 10px;
    box-shadow: none;
}

.img-profile {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    align-self: auto;
    cursor: pointer;
}

.img-profile:hover {
    opacity: 0.5
}

.image-icon {
    width: 80px;
    cursor: pointer;
}

ul.nav-tabs .nav-item button.nav-link {
    background-color: rgba(234, 234, 234, 1);
    color: #333333;
    box-sizing: border-box;
    border-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-color: rgba(204, 204, 204, 1);
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

ul.nav-tabs .nav-item button.nav-link.active {
    background-color: rgba(255, 255, 255, 1);
    color: rgb(60, 175, 138);
    box-sizing: border-box;
    border-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    border-bottom-style: solid;
    border-color: rgba(204, 204, 204, 1);
    border-bottom-color: rgb(204, 204, 204);
    border-bottom: 0px;
    border-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

ul.nav-tabs .nav-item {
    padding-right: 4px;
}

.hover-item:hover {
    text-decoration: underline;
}

.image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-wrapper:hover .del {
    opacity: 1;
}

.del {
    opacity: 0;
}

.image-wrapper:hover .img-profile {
    opacity: 0.5;
}

.cpa-fade-in {
    opacity: 1;
    animation: fadeIn 0.5s normal;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

.cpa-fade-out {
    opacity: 0;
    animation: fadeOut 0.5s normal;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
}

.add-link-wrapper {
    width: 137px;
    height: 43px;
    background: inherit;
    background-color: rgba(221, 221, 221, 0);
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}

div .browse-btn {
    width: 60px;
    height: 25px;
    background-color: #c0c0c0;
    text-align: center;
    cursor: pointer;
}

tr.role-perm-header {
    height: 40px;
    background-color: #F5F5F6;
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: none;
    /* text-align: center; */
}