.nav-card-menu {
    background-color: rgba(102, 102, 102, 1);
    border: none;
    border-radius: 0px;
    width: 350px;
    height: 100vh;
    display: flex;
    justify-content: center;
    z-index: 1;
    position: fixed;
    top: 60px;
    right: -100%;
    transition: 0.5s;
}

.nav-card-menu.active {
    right: 0;
}

.nav-item-container {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    width: 300px;
    height: 40px;
}