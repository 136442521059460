.modal-90w {
    min-width: 60%;
}

.clientMedicationsList thead tr {
    background-color: #dddddd;
}

.clientMedicationsList .sort {
    cursor: pointer;
}

.clientMedicationsList .sort:hover {
    color: rgba(45, 132, 105, 1);
}

.clientMedicationsList .sort:hover::after {
    font-family: FontAwesome;
    content: "\00a0\00a0\f0dc";
    font-size: 12px;
}

.clientMedicationsList td:nth-child(1) {
    width: 8%;
}

.clientMedicationsList td:nth-child(2) {
    width: 8%;
}

.clientMedicationsList td:nth-child(3) {
    width: 17%;
}

.clientMedicationsList td:nth-child(4) {
    width: 17%;
}

.clientMedicationsList td:nth-child(5) {
    width: 7%;
}

.clientMedicationsList td:nth-child(6) {
    width: 10%;
}

.clientMedicationsList td:nth-child(7) {
    width: 10%;
}

.clientMedicationsList td:nth-child(8) {
    width: 10%;
}

.clientMedicationsList td:nth-child(9) {
    width: 7%;
}

.clientMedicationsList td:nth-child(10) {
    width: 6%;
}
