.hospitalList thead tr {
    background-color: #dddddd;
}

.hospitalList .sort {
    cursor: pointer;
}

.hospitalList .sort:hover {
    color: rgba(45, 132, 105, 1);
}

.hospitalList .sort:hover::after {
    font-family: FontAwesome;
    content: "\00a0\00a0\f0dc";
    font-size: 12px;
}

.hospitalList td:nth-child(1) {
    width: 50%;
}

.hospitalList td:nth-child(2) {
    width: 25%;
}

.hospitalList td:nth-child(3) {
    width: 15%;
}

.hospitalList td:nth-child(4) {
    width: 10%;
}
