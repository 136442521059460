.containerSearch {
    position: relative;
    width: 400px;
    height: 36px;
    padding: 2px 2px 2px 10px;
    text-align: left;
    text-transform: none;
    background-color: transparent;
    border-color: transparent;
    letter-spacing: normal;
}

.containerSearch input {
    text-indent: 5px;
    border: 1px solid #ced4da;
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
}

.containerSearch input:focus {
    box-shadow: none;
    border: 2px solid #3CAF8A;
    background-color: #f2f2f2;
}

.labelStyling {
    font-family: 'Montserrat-SemiBold', 'Montserrat SemiBold', 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
}

div .modalInputStyling {
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #333333;
    border-radius: 5px;
}

div .modalInputStyling:focus {
    box-shadow: none;
    border: 2px solid #3CAF8A;
    background-color: #f2f2f2;
}

.navBarStyling {
    height: 60px;
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    box-shadow: none;
    z-index: 100;
}

div .headerButtonStyling {
    width: 140px;
    height: 40px;
    background-color: rgba(60, 175, 138, 1);
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-family: 'Montserrat-Medium', 'Montserrat Medium', 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
}

div .headerButtonStyling:hover {
    background-color: rgba(60, 175, 138, 0.8);
    color: #FFFFFF;
}

div .headerButtonStyling:focus, .headerButtonStyling:active, .headerButtonStyling.active, .headerButtonStyling:focus:active {
    background-image: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

    /* search box dropdown css goes from here */

    .autoCompleteInput {
        border: 1px solid hsla(218, 100%, 42%, 1);
        outline: none;
        padding: 0 0.5rem;
        height: 40px;
        border-radius: 5px;
      }
      
      .noSuggestions {
        color: #999;
        padding: 0.4rem;
        font-size: 0.9rem;
      }
      
      .suggestions {
        /* border: 1px solid rgba(60, 175, 138, 1); */
        border-top-width: 0;
        list-style: none;
        margin-top: 0;
        max-height: 200px;
        overflow-y: auto;
        padding-left: 0;
        width: 390px;
        background-color: #fff;
      }
      
      .suggestions::-webkit-scrollbar {
        width: 0.2rem;
        height: 0rem;
        border-radius: 20px;
      }
      
      .suggestions::-webkit-scrollbar-thumb {
        /* background-color: hsla(218, 100%, 42%, 1); */
        background-color: rgba(60, 175, 138, 1);
      }
      
      .suggestions li {
        padding: 0.5rem;
        font-size: 0.9rem;
        font-weight: 500;
      }
      
      .suggestionActive,
      .suggestions li:hover {
        /* background: linear-gradient(
          90deg,
          hsla(218, 100%, 42%, 0.6) 0%,
          hsla(0, 0%, 98%, 1) 200%
        ); */
        background: rgba(60, 175, 138, 1);
        color: #fff;
        cursor: pointer;
        font-weight: 700;
      }
      
      .suggestions li:not(:last-of-type) {
        /* border-bottom: 1px solid #999; */
      }
      
      /* search box dropdown css ends here */