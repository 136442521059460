.modal-90w {
    min-width: 60%;
}

.clientHealthAssessmentList thead tr {
    background-color: #dddddd;
}

.clientHealthAssessmentList .sort {
    cursor: pointer;
}

.clientHealthAssessmentList .sort:hover {
    color: rgba(45, 132, 105, 1);
}

.clientHealthAssessmentList .sort:hover::after {
    font-family: FontAwesome;
    content: "\00a0\00a0\f0dc";
    font-size: 12px;
}

.clientHealthAssessmentList td:nth-child(1) {
    width: 23%;
}

.clientHealthAssessmentList td:nth-child(2) {
    width: 22%;
}

.clientHealthAssessmentList td:nth-child(3) {
    width: 23%;
}

.clientHealthAssessmentList td:nth-child(4) {
    width: 22%;
}

.clientHealthAssessmentList td:nth-child(5) {
    width: 10%;
}
