div .leadStatusDropDownStyling {
    width: 211px;
    height: 40px;
    padding: 2px 2px 2px 10px;
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: normal;
    color: #333333;
    vertical-align: none;
    text-align: left;
    text-transform: none;
}

div .leadStatusDropDownStyling:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #ced4da;
}

thead .tHeadStyling {
    height: 40px;
    background-color: #F5F5F6;
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: none;
}

tr .tDataStyling {
    height: 40px;
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: none;
    font-family: 'Montserrat-Medium', 'Montserrat Medium', 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    font-size: 13px;
}

#tableBody tr.ClientTableRow:hover {
    background-color: rgba(245, 245, 246, 1);
}

.labelStyling {
    font-family: 'Montserrat-SemiBold', 'Montserrat SemiBold', 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
}

.addPhotoStyling {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background-color: rgba(221, 221, 221, 0);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(221, 221, 221, 1);
    margin-top: 1px;
}

div .photoBorder {
    position: relative;
    border-radius: 50%;
    height: 85px;
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: center
}

.card:hover {
    background-color: rgba(245, 245, 246, 1);
    cursor: pointer;
}

div .statusContainerStyling {
    display: inline-block;
    width: 120px;
    height: 30px;
    background-color: rgba(60, 175, 138, 1);
    border: none;
    border-radius: 5px;
    box-shadow: none;
}

div .tableWrapper {
    max-height: 150px;
    overflow: auto;
}

div .addContactWrapper {
    width: 137px;
    height: 43px;
    background: inherit;
    background-color: rgba(221, 221, 221, 0);
    border: none;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}